/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

.text-link {
  @apply text-blue-600 hover:underline hover:text-blue-800 decoration-1 underline-offset-2 decoration-blue-200 hover:decoration-blue-800;
}

.prose {
  @apply text-gray-900;
  @apply prose-a:font-normal prose-a:text-blue-600 hover:prose-a:underline hover:prose-a:text-blue-800;
  @apply prose-a:decoration-1 prose-a:underline-offset-2 ;
  @apply prose-blockquote:font-normal prose-blockquote:not-italic prose-blockquote:text-current;
}

.prose blockquote p:before,
.prose blockquote p:after {
  content: "";
}

.aa-DetachedOverlay {
  z-index:9998 !important;
}

.aa-DetachedSearchButton {
  height: 36px !important;
  font-size: 14px !important;
}
